import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const AddCategoryScreen = lazy(() => import('../app/pages/add/addCategoryScreen'));
const AddItemPayScreen = lazy(() => import('../app/pages/add/addItemPayScreen'));
const AddStockScreen = lazy(() => import('../app/pages/add/addStockScreen'));
const AddUsersScreen = lazy(() => import('../app/pages/add/addUsersScreen'));
const AddItemRejectedScreen = lazy(() => import('../app/pages/add/addItemRejectedScreen'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const ReadSpaceVentScreen = lazy(() => import('./pages/read/readSpaceVent'));


const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));


const ReadCategoryAddScreen = lazy(() => import('../app/pages/read/readCategoryAddScreen'));
const ReadStockAddScreen = lazy(() => import('../app/pages/read/readStockAddScreen'));
const ReadUsersAddScreen = lazy(() => import('../app/pages/read/readUsersAddScreen'));
const ReadItemPayAddScreen = lazy(() => import('../app/pages/read/readItemPayAddScreen'));
const ReadItemRejectedAddScreen = lazy(() => import('../app/pages/read/readItemRejectedAddScreen'));

const AppRoutes = ({defaultView="/dashboard"}) => {
  const [dRoute,setDRoute] = React.useState(defaultView)

  React.useEffect(() => {
    setDRoute(defaultView)
  },[defaultView])

    return dRoute === "/dashboard" ? (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/dashboard" component={ Dashboard } />
          <Route exact path="/readStockAddScreen" component={ ReadStockAddScreen } />
          <Route exact path="/readSpaceVentScreen" component={ ReadSpaceVentScreen } />
          <Route exact path="/readUsersAddScreen" component={ ReadUsersAddScreen } />
          <Route exact path="/readItemPayAddScreen" component={ ReadItemPayAddScreen } />
          <Route exact path="/readItemRejectedAddScreen" component={ ReadItemRejectedAddScreen } />
          <Route exact path="/readCategoryAddScreen" component={ ReadCategoryAddScreen } />
          <Route exact path="/addCategoryScreen" component={ AddCategoryScreen } />
          <Route exact path="/addItemPayScreen" component={ AddItemPayScreen } />
          <Route exact path="/addStockScreen" component={ AddStockScreen } />
          <Route exact path="/addUsersScreen" component={ AddUsersScreen } />
          <Route exact path="/addItemRejectedScreen" component={ AddItemRejectedScreen } />
          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/typography" component={ Typography } />
          <Route path="/form-Elements/basic-elements" component={ BasicElements } />
          <Route path="/tables/basic-table" component={ BasicTable } />
          <Route path="/icons/mdi" component={ Mdi } />
          <Route path="/charts/chart-js" component={ ChartJs } />

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />
          <Redirect to={dRoute} />
        </Switch>
      </Suspense>
    ) : (
        <Suspense fallback={<Spinner/>}>
          <Switch>
            <Route path="/user-pages/login-1" component={ Login } />
            <Route path="/error-pages/error-404" component={ Error404 } />
            <Route path="/error-pages/error-500" component={ Error500 } />
            <Redirect to={dRoute} />
          </Switch>
        </Suspense>
    );
}


export default AppRoutes;
