import React, { Component } from 'react';
import {Link, Route, withRouter} from 'react-router-dom';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';

class Sidebar extends Component {

  state = {};
  seconState = {}

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/basic-ui', state: 'basicUiMenuOpen'},
      {path:'/form-elements', state: 'formElementsMenuOpen'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));

  }
  loadData(){
    for(const [key,value] of Object.entries(JSON.parse(localStorage.getItem('Auth')))){
      this.seconState[key] = value
    }
  }

  render () {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html"><h3 className="text-white">PINK LADIES INVENTORY</h3></a>
          <a className="sidebar-brand brand-logo-mini" href="index.html"><h2 className="text-white">PLI</h2></a>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img className="img-xs rounded-circle " src={"https://cdn0.iconfinder.com/data/icons/flat-security-icons/512/lock-open-blue.png"} alt="profile" />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal"><Trans>{this.seconState.name}</Trans></h5>
                  <span  className="text-uppercase"><Trans>{this.seconState.rule}</Trans></span>
                </div>
              </div>

            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link"><Trans>Navigation</Trans></span>
          </li>
          <li className={ this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link>
          </li>

          <li className={ 'nav-item menu-items' }>
            <div className={ this.state.sectionAdd ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('sectionAdd') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-plus"></i>
              </span>
              <span className="menu-title"><Trans>Section d'ajout</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.sectionAdd }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/addCategoryScreen') ? 'nav-link active' : 'nav-link' } to="/addCategoryScreen"><Trans>Ajouter une catégorie</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/addItemPayScreen') ? 'nav-link active' : 'nav-link' } to="/addItemPayScreen"><Trans>Ajouter un achat</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/addStockScreen') ? 'nav-link active' : 'nav-link' } to="/addStockScreen"><Trans>Ajouter un stock</Trans></Link></li>
                  {
                    this.seconState.rule !== "admin" ? null :
                        <li className="nav-item"> <Link className={ this.isPathActive('/addUsersScreen') ? 'nav-link active' : 'nav-link' } to="/addUsersScreen"><Trans>Ajouter un utilisateur</Trans></Link></li>
                  }
                  <li className="nav-item"> <Link className={ this.isPathActive('/addItemRejectedScreen') ? 'nav-link active' : 'nav-link' } to="/addItemRejectedScreen"><Trans>Ajouter un reject</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li className={ 'nav-item menu-items' }>
            <div className={ this.state.sectionShow ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('sectionShow') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-eye"></i>
              </span>
              <span className="menu-title"><Trans>Section d'afficharge</Trans></span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.sectionShow }>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item"> <Link className={ this.isPathActive('/readCategoryAddScreen') ? 'nav-link active' : 'nav-link' } to="/readCategoryAddScreen"><Trans>Afficher les catégories</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/readItemPayAddScreen') ? 'nav-link active' : 'nav-link' } to="/readItemPayAddScreen"><Trans>Afficher les achats</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/readStockAddScreen') ? 'nav-link active' : 'nav-link' } to="/readStockAddScreen"><Trans>Afficher les stocks</Trans></Link></li>
                  {
                    this.seconState.rule !== "admin" ? null : <li className="nav-item"> <Link className={ this.isPathActive('/readUsersAddScreen') ? 'nav-link active' : 'nav-link' } to="/readUsersAddScreen"><Trans>Afficher les utilisateurs</Trans></Link></li>
                  }
                  <li className="nav-item"> <Link className={ this.isPathActive('/readItemRejectedAddScreen') ? 'nav-link active' : 'nav-link' } to="/readItemRejectedAddScreen"><Trans>Afficher les rejects</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/readSpaceVentScreen') ? 'nav-link active' : 'nav-link' } to="/readSpaceVentScreen"><Trans>Afficher les ventes</Trans></Link></li>
                </ul>
              </div>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.loadData()
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);

/**
 * <li className={ this.isPathActive('/basic-ui') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
 *             <div className={ this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
 *               <span className="menu-icon">
 *                 <i className="mdi mdi-laptop"></i>
 *               </span>
 *               <span className="menu-title"><Trans>Basic UI Elements</Trans></span>
 *               <i className="menu-arrow"></i>
 *             </div>
 *             <Collapse in={ this.state.basicUiMenuOpen }>
 *               <div>
 *                 <ul className="nav flex-column sub-menu">
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link' } to="/basic-ui/buttons"><Trans>Buttons</Trans></Link></li>
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link' } to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link' } to="/basic-ui/typography"><Trans>Typography</Trans></Link></li>
 *                 </ul>
 *               </div>
 *             </Collapse>
 *           </li>
 *           <li className={ this.isPathActive('/form-elements') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
 *             <div className={ this.state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
 *               <span className="menu-icon">
 *                 <i className="mdi mdi-playlist-play"></i>
 *               </span>
 *               <span className="menu-title"><Trans>Form Elements</Trans></span>
 *               <i className="menu-arrow"></i>
 *             </div>
 *             <Collapse in={ this.state.formElementsMenuOpen }>
 *               <div>
 *                 <ul className="nav flex-column sub-menu">
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link' } to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
 *                 </ul>
 *               </div>
 *             </Collapse>
 *           </li>
 *           <li className={ this.isPathActive('/tables') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
 *             <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
 *               <span className="menu-icon">
 *                 <i className="mdi mdi-table-large"></i>
 *               </span>
 *               <span className="menu-title"><Trans>Tables</Trans></span>
 *               <i className="menu-arrow"></i>
 *             </div>
 *             <Collapse in={ this.state.tablesMenuOpen }>
 *               <div>
 *                 <ul className="nav flex-column sub-menu">
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link' } to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
 *                 </ul>
 *               </div>
 *             </Collapse>
 *           </li>
 *           <li className={ this.isPathActive('/charts') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
 *             <div className={ this.state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('chartsMenuOpen') } data-toggle="collapse">
 *               <span className="menu-icon">
 *                 <i className="mdi mdi-chart-bar"></i>
 *               </span>
 *               <span className="menu-title"><Trans>Charts</Trans></span>
 *               <i className="menu-arrow"></i>
 *             </div>
 *             <Collapse in={ this.state.chartsMenuOpen }>
 *               <div>
 *                 <ul className="nav flex-column sub-menu">
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link' } to="/charts/chart-js"><Trans>Chart Js</Trans></Link></li>
 *                 </ul>
 *               </div>
 *             </Collapse>
 *           </li>
 *           <li className={ this.isPathActive('/icons') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
 *             <div className={ this.state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('iconsMenuOpen') } data-toggle="collapse">
 *               <span className="menu-icon">
 *                 <i className="mdi mdi-contacts"></i>
 *               </span>
 *               <span className="menu-title"><Trans>Icons</Trans></span>
 *               <i className="menu-arrow"></i>
 *             </div>
 *             <Collapse in={ this.state.iconsMenuOpen }>
 *               <div>
 *                 <ul className="nav flex-column sub-menu">
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link' } to="/icons/mdi"><Trans>Material</Trans></Link></li>
 *                 </ul>
 *               </div>
 *             </Collapse>
 *           </li>
 *           <li className={ this.isPathActive('/user-pages') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
 *             <div className={ this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('userPagesMenuOpen') } data-toggle="collapse">
 *               <span className="menu-icon">
 *                 <i className="mdi mdi-security"></i>
 *               </span>
 *               <span className="menu-title"><Trans>User Pages</Trans></span>
 *               <i className="menu-arrow"></i>
 *             </div>
 *             <Collapse in={ this.state.userPagesMenuOpen }>
 *               <div>
 *                 <ul className="nav flex-column sub-menu">
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/login-1"><Trans>Login</Trans></Link></li>
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link' } to="/user-pages/register-1"><Trans>Register</Trans></Link></li>
 *                 </ul>
 *               </div>
 *             </Collapse>
 *           </li>
 *           <li className="nav-item nav-category">
 *             <span className="nav-link"><Trans>More</Trans></span>
 *           </li>
 *           <li className={ this.isPathActive('/error-pages') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
 *             <div className={ this.state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('errorPagesMenuOpen') } data-toggle="collapse">
 *               <span className="menu-icon">
 *                 <i className="mdi mdi-lock"></i>
 *               </span>
 *               <span className="menu-title"><Trans>Error Pages</Trans></span>
 *               <i className="menu-arrow"></i>
 *             </div>
 *             <Collapse in={ this.state.errorPagesMenuOpen }>
 *               <div>
 *                 <ul className="nav flex-column sub-menu">
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-404">404</Link></li>
 *                   <li className="nav-item"> <Link className={ this.isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link' } to="/error-pages/error-500">500</Link></li>
 *                 </ul>
 *               </div>
 *             </Collapse>
 *           </li>
 */
